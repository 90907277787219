<template>
    <list type="my"></list>
</template>

<script>
import List from "./list.vue";

export default {
    name: "my",
    components: {List}
}
</script>

<style scoped>

</style>
